.login-wrapper {    
  align-items: center;
  display: flex;
  flex-direction: column;  
}

.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;  
  border-radius: 4px;
}

.content
{
    padding: 15px;    
}

.list
{
  background-color: #fff;  
  height: 80vh;
}

.listItem
{
  padding-left: 10px;    
}

.listHeader
{
  padding-left: 5px;
  font-weight: bold;
}

.deviceDropDown
{
  width: 170px;
}

.maplivelocation
{
  height: 80vh;  
  width: auto;
}

.unauthorized {  
  margin: auto;
  padding-top: 30vh;
  justify-content: center;
  height: 500px;
  width: 500px;  
}